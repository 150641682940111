import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { useSelector } from "react-redux";

import getUserByID from "../api/getUserByID";
import changeUserActiveStatus from "../api/changeUserActiveStatus";
import setUserDeletedStatus from "../api/setUserDeletedStatus";

import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";
import PlainContainer from "../components/PlainContainer";
import SingleUserBorrowHistory from "../components/SingleUserBorrowHistory";
import SingleUserInformation from "../components/SingleUserInformation";
import SingleUserStatus from "../components/SingleUserStatus";
import Button from "../components/Button";
import Modal from "react-modal";
import Footer from "../components/Footer";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import isActivityTokenSent from "../api/isActivityTokenSent"
import signAndSendSimpleTransaction from "../api/getSignedSimpleTransaction";
import { USER_STATUS } from "../utils/constants";

const UserView = ({ history }) => {

  const [user, setUser] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const { id } = useParams();
  const loggedUser = useSelector(state => state.authentication.user)
  const activationTokenId = loggedUser.token_id;

  const getUser = async () => {

    let response = await getUserByID(id);
    setUser(response)

  }

  useEffect(() => getUser(), [isModalOpen || isUserDeleteModalOpen || ""]);

  const activateDeactivateUser = async () => {

    let { result } = await changeUserActiveStatus(user.id)

    if (result.is_active === USER_STATUS.Active) {
      const isTokenSent = await isActivityTokenSent(user.id, activationTokenId)
      if (result.is_active === USER_STATUS.Active && !isTokenSent) {
        trySendTokens()
      } else if (result.is_active === USER_STATUS.Active && isTokenSent)
        console.log("activity token was already sent")
      else
        console.log("acount is already active")
    }

    setIsModalOpen(false);

  };
  const deleteUser = async () => {

    let { result } = await setUserDeletedStatus(user.id)


    setIsUserDeleteModalOpen(false);

  };

  const trySendTokens = async () => {
    const libraryAddress = loggedUser.libAddress;
    try {
      await signAndSendSimpleTransaction(
        {
          from: libraryAddress,
          to: user.public_key
        }
      );
    } catch (err) {
      console.log('error: ', err.message);
    }
  }

  const openActivateDeactivateModal = () => {
    setIsModalOpen(true);
  };
  const openUserDeleteModal = () => {
    setIsUserDeleteModalOpen(true);
  };

  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <PlainContainer
        widthInVw={95}
        customStyles={{
          position: "absolute",
          top: "9vh",
          left: "2vw",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        {/* <Button
          styleOptions={{ padding: "6px 12px" }}

          text={
            <div> Powrót
            </div>
          }
          onClick={() => history.goBack()}

        /> */}
        <Button
          text={
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
              />
              Powrót
            </div>
          }
          onClick={() => history.goBack()}
          styleOptions={{
            height: "100%",
            padding: "6px 14px"
          }}
        />

      </PlainContainer >

      <PlainContainer

        widthInVw={77}
        customStyles={{
          top: "14.5vh",
          position: "absolute",
          left: "2vw",
          paddingBottom: "0px",
          height: "25vh",
        }}
      >
        <SingleUserInformation user={user} />
      </PlainContainer>
      <PlainContainer
        heightInVh={14.5}
        widthInVw={17}
        customStyles={{
          top: "14.5vh",
          position: "absolute",
          left: "80vw",
          paddingTop: "20px",
          paddingBottom: "0px",
          height: "25vh",
        }}
      >
        <SingleUserStatus
          status={user?.is_active}
          date={user?.update_time}
          openActivateDeactivateModal={openActivateDeactivateModal}
          openUserDeleteModal={openUserDeleteModal}
        />

      </PlainContainer>
      <PlainContainer
        heightInVh={'auto'}
        widthInVw={95}
        customStyles={{
          top: "41vh",
          height: "auto",
          left: "2vw",
          position: "absolute",
          paddingBottom: "0px",
        }}
      >
        <SingleUserBorrowHistory user={user} />
      </PlainContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            height: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "2px 9px 15px 3px rgba(0,0,0,0.16)",
          },
          overlay: {
            backgroundColor: "rgba(255,255,255,0.4)",
          },
        }}
        contentLabel="Change user status modal"
      >
        <div style={{ fontSize: "1.3em" }}>
          Czy na pewno chcesz zmienić status konta tego czytelnika?
        </div>

        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <Button
            text="Tak"
            styleOptions={{
              display: "inline-block",
              marginRight: "3px",
            }}
            onClick={() => activateDeactivateUser()}
          />
          <Button
            text="Nie"
            styleOptions={{ display: "inline-block", marginLeft: "3px" }}
            onClick={() => setIsModalOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        isOpen={isUserDeleteModalOpen}
        onRequestClose={() => setIsUserDeleteModalOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            height: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "2px 9px 15px 3px rgba(0,0,0,0.16)",
          },
          overlay: {
            backgroundColor: "rgba(255,255,255,0.4)",
          },
        }}
        contentLabel="Change user status modal"
      >
        <div style={{ fontSize: "1.3em" }}>
          Czy na pewno chcesz usunąć tego czytelnika?
        </div>

        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <Button
            text="Tak"
            styleOptions={{
              display: "inline-block",
              marginRight: "3px",
            }}
            onClick={() => deleteUser()}
          />
          <Button
            text="Nie"
            styleOptions={{ display: "inline-block", marginLeft: "3px" }}
            onClick={() => setIsUserDeleteModalOpen(false)}
          />
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default withRouter(UserView);

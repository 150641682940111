export const shortenTableEntry = (entry, numberOfCharacters) =>
  entry && (entry.length > numberOfCharacters)
    ? `${entry.substr(0, numberOfCharacters)}...`
    : entry;

export const formatAddressMiddle = (address) =>
  address.length > 18
    ? `${address.substr(0, 6)}...${address.substr(
        address.length - 6,
        address.length
      )}`
    : address;

export const prepareStringForDisplay = (str) => {
  try {
    str = decodeURIComponent(str?.replace(/\+/g, " "));
    return str;
  } catch (e) {
    // if error caught then string is not to be decoded and just return it
    return str;
  }
};

export const getDateTime = (date) => {
  const formattedDate = new Date(date);
  if (date !== null)
    return `${formattedDate.toLocaleDateString()} ${formattedDate.toLocaleTimeString()}`;
  else return date;
};

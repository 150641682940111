import React, { useState } from "react";

const Button = ({
  text,
  isDisabled,
  styleOptions,
  onClick,
  type = "button",
}) => {
  const [hover, setHover] = useState(false);
  return (
    <button
      type={type}
      disabled={isDisabled}
      style={{
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: "black",
        background: isDisabled
          ? "gray"
          : "black",
        // boxShadow: "2px 4px 18px -5px rgba(0,0,0,0.31)",
        color: isDisabled ? "#bbb" : hover ? "yellow" : "#f4f4f4",
        borderRadius: "8px",
        padding: "6px 30px",
        borderWidth: "0px",
        opacity: hover ? "0.75" : "1",
        fontSize: "1.7vh",
        ...styleOptions,
      }}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {text}
    </button>
  );
};
export default Button;

export const ADDRESS_STRING_LENGTH = 4;
export const APP_VERSION = "v1.1";
export const LOCAL_SERVER_URL = "http://localhost:3001";
// export const SERVER_URL = 'http://localhost:3001'

export const SERVER_URL = (() => {
  const { REACT_APP_SERVER_URL, NODE_ENV } = process.env;

  if (NODE_ENV === 'production' && REACT_APP_SERVER_URL === undefined) {
    throw new Error('SERVER_URL is not specified')
  }

  console.log("SERVER_URL", REACT_APP_SERVER_URL);
  if (REACT_APP_SERVER_URL === undefined) {
    if( NODE_ENV === 'production') return "https://prod-dhunter.ew.r.appspot.com";
    if( NODE_ENV === 'development') return "https://productiona.lm.r.appspot.com";
    else
      return LOCAL_SERVER_URL;

  }

  return REACT_APP_SERVER_URL;
})();

export const BASE_32_CHAR_SET = "abcdefghijkmnpqrstuvwxyz23456789";

export const TOKENS_POLLING_INTERVAL = 5000;

export const PENDING_TRANSACTION_POLLING_INTERVAL = 200;

export const AUDIOBOOK_PROTOCOL = "audiobook://";

export const TRANSACTION_STATES = {
  Pending: "PENDING",
  Accepted: "ACCEPTED",
  Rejected: "REJECTED",
  Unknown: "UNKNOWN",
};

export const USER_STATUS = {
  Active: 1,
  Deleted: -1,
  Inactive: 0,
};

export const QR_CODE_TYPES = {
  BINARY_NONCE: "n",
  TEXT_NONCE: "6",
  CREATE_TOKEN: "t",
  REQUEST_TRANSACTION: "r",
  LOGIN_USER: "l",
};

export const NOTIFICATION_TYPES = {
  EMPLOYEE_ADDED: "Dodano pracownika",
  USER_ADDED: "Dodano czytelnika",
  LIBRARY_ADDED: "Dodano bibliotekę",
  LIBRARY_EDITED: "Zaktualizowano bibliotekę",
  DATA_UPDATED: "Zaktualizowano dane",
};

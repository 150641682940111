import AwaitingFragmentTableContents from "./AwaitingFragmentTableContents";
import AwaitingFragmentTableHeaders from "./AwaitingFragmentTableHeaders";

const AwaitingFragmentTable = ({ names, sizes }) => {
  return (
    <>
      <AwaitingFragmentTableHeaders names={names} sizes={sizes} />
      <AwaitingFragmentTableContents sizes={sizes} />
    </>
  );
};

export default AwaitingFragmentTable;

import TableEntryCell from "../TableEntryCell";

const AudiobooksListFragmentTableEntry = ({ audiobook, sizes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        float: "right",
        height: "3.5vh",
      }}
    >
      <TableEntryCell value={audiobook.title} size={sizes[0]} />
      <TableEntryCell value={audiobook.author} size={sizes[1]} />
      <TableEntryCell value={audiobook.numberOfCopies} size={sizes[2]} />
      <TableEntryCell value={audiobook.sumOfBorrowings} size={sizes[3]} />
      <TableEntryCell
        value={audiobook.numberOfWaitingClients}
        size={sizes[4]}
      />
    </div>
  );
};

export default AudiobooksListFragmentTableEntry;

import TableEntry from "./TableEntry";

const TableRow = ({ row, onClick }) => {
  return (
    <div
      className="row table-row"
      style={{ fontSize: "1.5vh", lineHeight: "1.9vh" }}
      {...row.getRowProps()}
      onClick={() => {

        if (row.values.id) onClick(row.values.id)
        else if (row.original.user_id) onClick(row.original.user_id)
        else if (row.values.token_symbol) onClick(row.values.token_symbol)

      }}
    >
      {row.cells.map((cell, index) => {
        return <TableEntry cell={cell} key={index} />;
      })}
    </div>
  );
};

export default TableRow;

import { withRouter } from "react-router";
import Background from "../components/Background";
import LibraryEmployeesTable from "../components/LibraryEmployeesTable";
import NavTopBar from "../components/NavTopBar";
import Footer from "../components/Footer";

const EmployeesView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <LibraryEmployeesTable />
      <Footer />
    </>
  );
};

export default withRouter(EmployeesView);

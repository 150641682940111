import { useEffect, useState } from "react";
import getLibrariesList from "../api/getLibrariesList";

const LibraryFilter = ({ onChange }) => {
  const [libraryList, setLibraryList] = useState([]);
  useEffect(() => {
    const getLibraries = async () => {
      const response = await getLibrariesList();
      setLibraryList(response);
    };
    getLibraries();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <select
        style={{
          float: "left",
          height: "100%",
          border: "0",
          outline: "none",
          borderRadius: "8px",
          padding: "0.15rem 0.5rem",
          marginLeft: "3px",
          maxWidth: "10vw",
        }}
        name="libraryFilter"
        id="libraryFilter"
        onChange={onChange}
      >
        <option value={"none"} style={{ fontWeight: "bold" }}>
          Pokaż wszystkie
        </option>
        {libraryList.map((lib) => (
          <option value={lib.publicKey} key={lib.publicKey}>
            {lib.libraryName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LibraryFilter;

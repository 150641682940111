const VerticalLine = ({ customStyles = {} }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "2px",
        backgroundColor: "rgb(96, 78, 151)",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        opacity: 0.15,
        ...customStyles,
      }}
    ></div>
  );
};

export default VerticalLine;

import * as types from '../redux/types/types'

// let user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? { loggedIn: true, user } : {};

const user = (() => {
  const cachedUserData = localStorage.getItem('user')
  if (cachedUserData) {
    return JSON.parse(cachedUserData);
  }
})()

const initialState = {
  user
}
export function authentication(state = initialState, action) {
  switch (action.type) {
    case types.LOG_IN_USER:
      
      localStorage.setItem('user', JSON.stringify(action.user))

      return {
        ...state,
        user: action.user
      };
    case types.LOG_OUT_USER:

      localStorage.removeItem('user')

      return {
        ...state,
        user: ''
      };
    default:
      return state
  }
}
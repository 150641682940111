import { SERVER_URL, LOCAL_SERVER_URL } from "../utils/constants";

const getUserByID = async (id) => {
  const response = await fetch(`${SERVER_URL}/getUserByID?id=${id}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 400) {
    const { errors } = await response.json();
    return { errors };
  }
  return await response.json();
};

export default getUserByID;

const TableEntry = ({ cell }) => {
  return (
    <div
      className={cell.column.columnType}
      style={{ textAlign: 'left', padding: '5px 0px 5px 0px' }}
      {...cell.getCellProps()}
    >
      {cell.render('Cell')}
    </div>
  );
};

export default TableEntry;

import React from "react";
import { withRouter } from "react-router-dom";
import TopBarNavigation from "./TopBarNavigation";
import LoggedUser from "./LoggedUser";
import TopBarButtonsPanel from "./TopBarButtonsPanel";
import HorizontalDivider from "./HorizontalDivider";

const NavTopBar = ({ history }) => {
  const pathname = history.location.pathname;
  return (
    <>
      {/* <DHunterLogo /> */}
      <TopBarNavigation location={pathname} />

      <LoggedUser />
      <TopBarButtonsPanel history={history} />

      <HorizontalDivider />
    </>
  );
};

export default withRouter(NavTopBar);

// import { withRouter } from "react-router-dom";
import Button from "../Button"
import "./RegisterContainer.css";

const RegisterCredentials = ({ history }) => {
  return (
    <div className="login-view-credentials">
      <form className="login-view-form">

        <div class="form-group">
          <label htmlFor="user-login" className="login-form-label">
            Login
          </label>
          <input
            id="user-login"
            name="username"
            className="login-form-input"
            type="text"
            />
        </div>

        <div class="form-group">
          <label htmlFor="user-email" className="login-form-label">
            Email
          </label>
          <input
            id="user-email"
            name="email"
            className="login-form-input"
            type="email"
            />
        </div>

        <div class="form-group">
          <label htmlFor="user-password" className="login-form-label">
            Password
          </label>
          <input
            id="user-password"
            name="password"
            className="login-form-input"
            type="password"
            />
        </div>

        <div class="form-group">
          <label htmlFor="user-password" className="login-form-label">
            Repeat password
          </label>
          <input
            id="user-password"
            name="repeat-password"
            className="login-form-input"
            type="password"
          />
        </div>
        <div class="form-group">
          <Button
            text={"Register"}
            isDisabled={false}
            styleOptions={{
              margin: "2em auto",
              width: "150px",
            }}
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/users`);
            }}
          />
        </div>
      </form>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: createThunkActions(dispatch, {
//     onRegisterUserInLibrarian: (username, email, password) =>
//       dispatch({ type: 'REGISTER_LIBRARIAN_USER', userdata: { username, email, password} }),
//   })
// });

// export default connect(null, mapDispatchToProps)(LoginCredentials);
export default RegisterCredentials;

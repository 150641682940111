import React from "react";

const TopBarHeader = ({ header }) => (
  <div
    style={{
      marginTop: "25px",
      width: "100%",
      color: "black",
      letterSpacing: "0.3px",
    }}
  >
    <h1
      style={{
        fontSize: "3.2rem",
        fontWeight: "bold",
        textAlign: "center",

      }}
    >
      {header}
    </h1>
  </div>
);

export default TopBarHeader;

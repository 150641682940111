import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";

import AudiobooksTable from "../components/AudiobooksTable";
import Footer from "../components/Footer";
const AudiobooksView = () => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <AudiobooksTable />
      <Footer />
    </>
  );
};

export default AudiobooksView;

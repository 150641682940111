import { useEffect, useState } from "react";
import changeUserNotes from "../api/changeUserNotes";
import { enqueueSnackbar } from 'notistack';

const SingleUserInformationErrors = ({ user }) => {
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);


  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);
  return (
    <div
      className="row"
      style={{
        alignItems: "center",
        padding: "5px 0px 0px",
        colspan: "100%",
        position: "absolute",
        top: "19vh",
      }}
    >
      {!loading ? (
        <>
          <div class="input-group" style={{ width: "100%", padding: "0 0.5vw " }} >
            <div class="input-group-prepend" >
              <span class="input-group-text" id="inputGroup-sizing-sm" style={{ width: "5vw", height: "3.5vh", fontSize: "1.5vh" }}  >
                Uwagi
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              defaultValue={user?.notes}
              spellCheck="false"
              style={{ height: "3.5vh", width: "64vw", fontSize: "1.5vh" }}
              onChange={t => {
                setNotes(t.target.value.slice(0, 255))
                setChanged(true)
              }}

            ></input>
            {<div class="input-group-append" >

              <button
                style={{
                  height: "3.5vh",
                  width: "5vw",
                  fontSize: "1.3vh",

                }}
                class="btn btn-outline-secondary"
                type="Button"

                disabled={!changed}
                onClick={function () {
                  addNotes(user.id, notes)
                  enqueueSnackbar("Zapisano uwagi")
                  setChanged(false)
                }
                }
              >Zapisz</button>
            </div>}
          </div>
        </>
      ) : (null)
      }
    </div >
  );
};

const addNotes = async (id, notes) => {
  let { result } = await changeUserNotes(id, notes);
};


export default SingleUserInformationErrors;

import React from "react";
import Background from "../components/Background";
import RegisterContainer from "../components/RegisterViewComponents/RegisterContainer";
import LogoAndHeaderTopBar from "../components/LogoAndHeaderTopBar";

class RegisterView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-login-container">
        <LogoAndHeaderTopBar />
        <Background height="38vh" />
        <RegisterContainer />
      </div>
    );
  }
}

export default RegisterView;

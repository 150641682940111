import AudiobooksListFragmentTableContents from "./AudiobooksListFragmentTableContents";
import AudiobooksListFragmentTableHeaders from "./AudiobooksListFragmentTableHeaders";

const AudiobooksListFragmentTable = ({ names, sizes }) => {
  return (
    <>
      <AudiobooksListFragmentTableHeaders names={names} sizes={sizes} />
      <AudiobooksListFragmentTableContents sizes={sizes} />
    </>
  );
};

export default AudiobooksListFragmentTable;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PlainContainer from "../PlainContainer";
import "./LoginContainer.css";
import VerticalLine from "../VerticalLine";
import LoginCredentials from "./LoginCredentials";
import LoginQRCode from "./LoginQRCode";

class LoginViewQRContainer extends Component {
  render() {
    return (
      <div
      // style={{
      //   position: "fixed",
      //   top: "0",
      //   left: "0",
      //   width: "100vw",
      //   height: "100vh",
      //   zIndex: 100,
      //   backgroundImage:
      //     "linear-gradient(rgba(109,200,245,0.6) ,rgba(164,164,244,0.35))",
      // }}
      >
        <PlainContainer
          widthInVw={50}
          heightInVh={65}
          customStyles={{
            top: "15vh",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <>
            <div className="login-view-form-qr-container">
              <LoginCredentials />
              <VerticalLine />
              <LoginQRCode />
            </div>
            {/* <LoginGoogle /> */}
          </>
        </PlainContainer>
      </div>
    );
  }
}

export default withRouter(LoginViewQRContainer);

import TableHeader from "./TableHeader";

const TableHeaders = ({ headerGroups }) => {
  return (
    <>
      {headerGroups.map((headerGroup, index) => (
        <TableHeader headerGroup={headerGroup} key={index} />
      ))}
    </>
  );
};

export default TableHeaders;

import { LOCAL_SERVER_URL, SERVER_URL } from '../utils/constants'

const getAudioBooksPurchaseLink = async (payload) => {
    const {
        address,
        jwtToken
    } = payload

    // console.log(payload)

    const response = await fetch(
        `${SERVER_URL}/getAudioBooksPurchaseLink`, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            address,
            jwtToken
        }),
    }
    )
    if (response.status === 403) {
        throw new Error("Session expired")
    }
    if (response.status === 400) {
        const { errors } = await response.json()
        return { errors }
    }

    const data = await response.json()
    return data;
}
export default getAudioBooksPurchaseLink

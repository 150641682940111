import { SERVER_URL, LOCAL_SERVER_URL } from "../utils/constants";

const changeEmployeeActiveStatus = async (user) => {
  const response = await fetch(
    `${SERVER_URL}/changeEmployeeActiveStatus`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user }),
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    return { errors };
  }
  return await response.json();
};

export default changeEmployeeActiveStatus;

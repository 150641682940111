import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";
import UsersTable from "../components/UsersTable";
import Footer from "../components/Footer";
const UsersView = () => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <UsersTable />
      <Footer />
    </>
  );
};

export default UsersView;

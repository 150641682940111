import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
import Background from "../components/Background";
import Button from "../components/Button";
import LibrariesTable from "../components/LibrariesTable";
import NavTopBar from "../components/NavTopBar";
import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";
import Footer from "../components/Footer";

const RegisterLibWorkerView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text={
            <div style={{
              display: "flex",
              color: "#f4f4f4"
            }}>
              Dodaj bibliotekę
            </div>
          }
          styleOptions={{
            float: "right",
            backgroundColor: "white",
            backgroundImage: "none",
            height: "100%",
            color: "black",
          }}
          onClick={() => history.push("/libraries/add")}
        />
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={'auto'}
        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <LibrariesTable />
      </PlainContainer>
      <Footer />
    </>
  );
};

export default withRouter(RegisterLibWorkerView);

const AudiobooksListFragmentTableHeader = ({
  name = "Nagłówek",
  size = "25vw",
}) => {
  return (
    <div
      style={{
        width: size,
        textAlign: "center",
        height: "3vh",
        lineHeight: "3vh",
        // borderLeft: "solid rgba(96, 78, 151, 0.4) 1px",
        borderBottom: "solid rgba(96, 78, 151, 0.4) 1px",
      }}
    >
      {name}
    </div>
  );
};

export default AudiobooksListFragmentTableHeader;

import React from "react";
import logoIcon from "../icons/logo.svg";

const DHunterLogo = () => (
  <div
  // style={{
  //   position: "absolute",
  //   left: "2vw",
  //   top: "20px",
  //   // textAlign: "center",
  // }}
  >
    {/* <Link to={`${process.env.PUBLIC_URL}/users`} style={{ textDecoration: "none", }}> */}
    <img
      src={logoIcon}

      style={{
        height: "calc(1.6vw + 14px)",
      }}
      alt=""
    />
    <div
      style={{
        color: "black",
        lineHeight: "22px",
        fontSize: "calc(0.25vw + 5px)",
        textAlign: "center",
        textTransform: "uppercase",
        letterSpacing: "0.3px",
      }}
    >
      Librarian
    </div>
    {/* </Link> */}
  </div>
);

export default DHunterLogo;

import { LOCAL_SERVER_URL, SERVER_URL } from '../utils/constants'

const loginUserInLibrarian = async (userData) => {
  const {
    username,
    password,
    sessionId,
    jwtToken
  } = userData

  const response = await fetch(
    `${SERVER_URL}/loginUserInLibrarian`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
      sessionId,
      jwtToken
    }),
  }
  )
  if (response.status === 400) {
    const { errors } = await response.json()
    return { errors }
  }
  const data = await response.json()
  // console.log("JWTTOKEN", data.jwtToken)
  return data;
}
export default loginUserInLibrarian

import React from "react";
import { connect } from "react-redux";

import TopBarHeader from "./TopBarHeader";

const LogoAndHeaderTopBar = (props) => (
  <React.Fragment>
    {props.shouldHeaderRender && <TopBarHeader header="Panel Biblioteczny" />}

  </React.Fragment>
);

const mapStateToProps = (state) => ({
  shouldHeaderRender: !state.emitentId,
});
export default connect(mapStateToProps)(LogoAndHeaderTopBar);

import React, { useEffect, useState } from 'react'
import Button from "../Button";

import { useParams, useLocation } from 'react-router';

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { enqueueSnackbar } from 'notistack';

import { generatePrivateKey, getAddressFromPrivateKey } from '../../utils/crypto';
import { generatePassword } from '../../utils/generators'
import { NOTIFICATION_TYPES } from '../../utils/constants'

import registerLibEmitent from '../../api/registerLibEmitent'

import jsPDF from "jspdf";
import QRCode from 'qrcode'
import '../../icons/OpenSans-Regular-normal';

import getEmployeeByID from '../../api/getEmployeeByID';
import updateLibrary from '../../api/updateLibrary';


const userSchema = yup.object().shape({
    libraryName: yup.string().required('Podaj nazwę biblioteki.'),
    street: yup.string().required('Podaj ulicę.'),
    username: yup.string().required('Podaj nazwę użytkownika.'),
    postCode: yup.string().required('Podaj kod pocztowy.').length(5, `Kod pocztowy musi mieć 5 cyfr (nie wpisuj "-"`),
    city: yup.string().required('Podaj miasto.'),
    email: yup.string().required('Podaj email.').email(),
    phoneNumber: yup.string().required("Podaj numer telefonu.").min(9, "Numer telefonu musi mieć przynajmniej 9 cyfr"),
    houseNo: yup.string().required("Podaj numer budynku."),
    verificationCode: yup.string().required("Podaj skrót nazwy biblioteki.").max(4, "Skrót biblioteki musi mieć maks. 4 litery"),
    password: yup.string().required('Podaj lub wygeneruj hasło.').min(8, "Hasło musi mieć przynajmniej 8 znaków").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/, "Hasło musi zawierać przynajmniej 1 dużą literę, 1 małą literę i 1 cyfrę"),
    maxBorrowTime: yup.number().typeError('').required('Podaj maksymalny czas wypożyczenia').integer().moreThan(0, "minimalna wartość 1").lessThan(22, "maksymalna wartość 21"),
    maxBorrows: yup.number().typeError('').required('Podaj maksymalną liczbę wypożyczeń (od 1 do 5)').integer().moreThan(0, "minimalna wartość 1").lessThan(6, "maksymalna wartość 5"),
});

const RegisterLibrary = ({ props }) => {

    const [privateKey, setPrivateKey] = useState('')
    const [publicKey, setPublicKey] = useState('')

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [libraryName, setLibraryName] = useState('');
    const [street, setStreet] = useState('');
    const [houseNo, setHouseNo] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [activationToken, setActivationToken] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    const [username, setUsername] = useState('')
    const [isEmailTaken, setEmailTaken] = useState('')
    const [isUsernameTaken, setUsernameTaken] = useState('')

    const [maxBorrows, setMaxBorrows] = useState('')
    const [maxBorrowTime, setMaxBorrowTime] = useState('')

    const [walletGenerated, setWalletGenerated] = useState(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(userSchema),
    });
    const { id: chosenLibraryID } = useParams();
    const { pathname } = useLocation();

    const passGenerator = () => {
        let newPass = generatePassword();
        setPassword(newPass);
    }

    const generateWalletCredentials = () => {

        let privKey = generatePrivateKey();
        let publicKey = getAddressFromPrivateKey(privKey);
        setPrivateKey(privKey)
        setPublicKey(publicKey)
        setWalletGenerated(true)
    }

    const verifyUsername = (value) => {
        setUsername(value);
    }

    const verifyActivationToken = (value) => {
        setActivationToken(value);
    }

    const verifyEmail = (value) => {
        setEmail(value);
    }

    const verifyPhoneNumber = (value) => {
        setPhoneNumber(value);
    }

    const verifyVerificationCode = (value) => {
        setVerificationCode(value);
    }

    const verifyMaxBorrowTime = (value) => {
        setMaxBorrowTime(value);
    }

    const verifyMaxBorrows = (value) => {
        setMaxBorrows(value);
    }


    const emptyForm = () => {
        setPrivateKey('');
        setPublicKey('');
        setPassword('');
        setEmail('');
        setLibraryName('');
        setStreet('');
        setHouseNo('');
        setPostCode('');
        setCity('');
        setUsername('');
        setPhoneNumber('');
        setActivationToken('');
        setWalletGenerated(false);
        setVerificationCode('');
        setMaxBorrows('');
        setMaxBorrowTime('');

    }

    const print = (data) => {
        let doc = new jsPDF();

        let stringToPDF = "Twoje dane do logowania\n"
            + "\nlogin: " + data.username
            + "\nhasło: " + password
            + "\nklucz prywatny: " + privateKey
            + "\nklucz publiczny: " + publicKey

        QRCode.toDataURL(privateKey)
            .then(url => {

                doc.addImage(url, 15, 60)

                doc.setFont("OpenSans-Regular");
                doc.setFontSize(11);
                doc.text(stringToPDF, 20, 20);
                doc.save(data.username + "-dane-do-logowania.pdf");
            })
            .catch(err => {
                console.error(err)
            })


    };

    const onSubmit = data => {
        let library = {
            user_id: chosenLibraryID,
            username: data.username,
            userEmail: data.email,
            libraryName: data.libraryName,
            emitentName: data.libraryName,
            postCode: data.postCode,
            city: data.city,
            houseNo: data.houseNo,
            phoneNumber: data.phoneNumber,
            street: data.street,
            token_id: data.activationToken,
            verificationCode: data.verificationCode,
            password,

            maxBorrowTime: data.maxBorrowTime,
            maxBorrows: data.maxBorrows,

            publicKey,
            privateKey,

        }

        const libraryJson = JSON.stringify(library);
        if (pathname.search("edit") > 0) {
            const userFound = getEmployeeByID(chosenLibraryID);
            if (userFound) {
                updateLibrary(libraryJson)
                    .then(result => {
                        console.log(result)
                        if (result === 200) {
                            enqueueSnackbar(NOTIFICATION_TYPES.LIBRARY_EDITED)
                            //print(data) // this does not work when editing, as privatKey is null
                        }
                    });
            }
            else console.log("user id not found in the db");
        }
        else {
            console.log("adding library");
            registerLibEmitent(libraryJson)
                .then(result => {
                    console.log(result)
                    if (result === 200) {
                        enqueueSnackbar(NOTIFICATION_TYPES.LIBRARY_ADDED)
                        emptyForm();
                        print(data)
                    }
                })
        }
    }

    useEffect(() => {
        async function getLibrary() {
            console.log(chosenLibraryID);
            if (chosenLibraryID !== undefined) {
                let response = await getEmployeeByID(chosenLibraryID)
                console.log(response);
                setEmail(response.email);
                setUsername(response.username);
                setPassword(response.password);
                setStreet(response.street_address);
                setHouseNo(response.house_number);
                setPostCode(response.postcode);
                setCity(response.city);
                setLibraryName(response.industry);
                setPhoneNumber(response.phone_number);
                setActivationToken(response.token_id);
                setVerificationCode(response.verification_code);

                setMaxBorrows(response.maxBorrows);
                setMaxBorrowTime(response.maxBorrowTime);
            }
        }
        getLibrary()
    }, []);

    function showSnackbar(message) {
        enqueueSnackbar(message)
    }

    return (
        <div className="container pl-3 pr-3">

            <form onSubmit={handleSubmit(onSubmit)} className="row justify-content-around">
                <div className="col-6">

                    <div className="form-group">
                        <label htmlFor="">Biblioteka (nazwa) {errors.libraryName && <span className="text-danger">({errors.libraryName.message})</span>}</label>
                        <input className="form-control" type="text" name="libraryName" ref={register} onChange={(e) => setLibraryName(e.target.value)} value={libraryName} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Ulica {errors.street && <span className="text-danger">({errors.street.message})</span>}</label>
                        <input className="form-control" type="text" name="street" ref={register} onChange={(e) => setStreet(e.target.value)} value={street} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Numer budynku {errors.houseNo && <span className="text-danger">({errors.houseNo.message})</span>}</label>
                        <input className="form-control" type="text" name="houseNo" ref={register} onChange={(e) => setHouseNo(e.target.value)} value={houseNo} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Kod pocztowy {errors.postCode && <span className="text-danger">({errors.postCode.message})</span>}</label>
                        <input className="form-control" type="text" placeholder="(bez '-') np. 40123" name="postCode" ref={register} value={postCode}
                            onChange={(e) => setPostCode(e.target.value)} />
                    </div>

                    <div className="form-group" >
                        <label htmlFor="">Miasto {errors.city && <span className="text-danger">({errors.city.message})</span>}</label>
                        <input className="form-control" type="text" name="city" ref={register} onChange={(e) => setCity(e.target.value)} value={city} />
                    </div>

                    <div className="form-group" >
                        <label htmlFor="">Skrót nazwy {errors.verificationCode && <span className="text-danger">({errors.verificationCode.message})</span>}</label>
                        <input className="form-control" type="text" name="verificationCode" ref={register} onChange={(e) => verifyVerificationCode(e.target.value)} value={verificationCode} placeholder="Maks. cztery litery np. ABCD" />
                    </div>

                </div>

                <div className="col-6">

                    <div className="form-group">
                        <label htmlFor="email">Adres email {(errors.email || isEmailTaken) && <span className="text-danger">({errors.email.message})</span>}</label>
                        <input className="form-control" type="email" name="email" ref={register} onChange={(e) => verifyEmail(e.target.value)} value={email} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="username">Nazwa użytkownika {(errors.username || isUsernameTaken) && <span className="text-danger">({errors.username.message})</span>}</label>
                        <input className="form-control" type="text" name="username" ref={register} onChange={(e) => verifyUsername(e.target.value)} value={username} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="username">Numer telefonu {(errors.phoneNumber) && <span className="text-danger">({errors.phoneNumber.message})</span>}</label>
                        <input className="form-control" type="text" name="phoneNumber" ref={register} onChange={(e) => verifyPhoneNumber(e.target.value)} value={phoneNumber} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Hasło {(errors.password) && <span className="text-danger">({errors.password.message})</span>}</label>
                        <div className="row">
                            <div className="col-9">
                                <input className="form-control" style={{ marginBottom: "0px", height: "100%" }} type="text" name="password" value={password} ref={register} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="col-3">
                                <Button text="Generuj" onClick={() => passGenerator()} styleOptions={{ maxWidth: "100%" }} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="username">Maks. czas wypożyczenia {(errors.maxBorrowTime) && <span className="text-danger">({errors.maxBorrowTime.message})</span>}</label>
                        <input className="form-control" type="text" name="maxBorrowTime" ref={register} onChange={(e) => verifyMaxBorrowTime(e.target.value)} value={maxBorrowTime} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Maks. wypożyczeń {(errors.maxBorrows) && <span className="text-danger">({errors.maxBorrows.message})</span>}</label>
                        <input className="form-control" type="text" name="maxBorrows" ref={register} onChange={(e) => verifyMaxBorrows(e.target.value)} value={maxBorrows} />
                    </div>

                    {pathname.search("edit") > 0 ?
                        <div className="form-group">
                            <label htmlFor="activationToken">Token aktywacyjny</label>
                            <input className="form-control" type="text" name="activationToken" ref={register} onChange={(e) => verifyActivationToken(e.target.value)} value={activationToken} />
                        </div> : null}

                    {pathname.search("add") > 0 ?
                        <div className="form-group">
                            <label htmlFor="wallet" >Wallet</label>
                            <input disabled className="form-control" type="text" name="privateKey" value={privateKey} style={{ marginBottom: "0.5rem" }} />
                            <div className="row">
                                <div className="col-9">
                                    <input disabled className="form-control" type="text" name="publicKey" value={publicKey}
                                        style={{ marginBottom: "0px", height: "100%" }} />
                                </div>
                                <div className="col-3">
                                    <Button text="Generuj" isDisabled={walletGenerated} onClick={() => generateWalletCredentials()}
                                        styleOptions={{ maxWidth: "100%" }} />
                                </div>
                            </div>
                        </div> : null}
                </div>

                <Button type="submit" text="Zapisz bibliotekę i generuj podsumowanie" />

            </form>

        </div>
    );

}

export default RegisterLibrary;
import AwaitingFragmentTableEntry from "./AwaitingFragmentTableEntry";
import { useEffect } from "react";

let users = require("../../mock/mock_awaiting_users.json");

const AwaitingFragmentTableContents = ({ sizes }) => {
  useEffect(() => {
    console.log(users);
  }, []);
  return (
    <div
      style={{
        height: "18vh",
        width: "100%",
        float: "right",
        // backgroundColor: "black",
        overflowY: "overlay",
      }}
    >
      {users.map((user) => {
        return (
          <AwaitingFragmentTableEntry user={user} sizes={sizes} key={user.id} />
        );
      })}
    </div>
  );
};

export default AwaitingFragmentTableContents;

import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const loggedUser = useSelector(state => state.authentication.user)

  return (
    <Route
    {...rest}
    render={(props) => {
      if (loggedUser) {
        return <Component />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
  )
}

export default ProtectedRoute;
import * as types from "../redux/types/types";

const state = (() => {
  const cachedStatus = localStorage.getItem("employeesStatusFilter");
  if (cachedStatus) {
    return JSON.parse(cachedStatus);
  } else {
    localStorage.setItem("employeesStatusFilter", false);
    return false;
  }
})();

const initialStore = {
  state,
};

const showUnactiveEmployees = (store = initialStore, action) => {
  switch (action.type) {
    case types.SHOW_ACTIVE_EMPLOYEES:
      localStorage.setItem("employeesStatusFilter", false);
      return { ...store, state: false };
    case types.SHOW_ALL_EMPLOYEES:
      localStorage.setItem("employeesStatusFilter", true);
      return { ...store, state: true };
    default:
      return store;
  }
};

export default showUnactiveEmployees;

import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";

const TableMain = ({ columns, rowsData, action }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data: rowsData, initialState: { PageSize: 10 } },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;


  return (
    <div
      className="container"
      {...getTableProps()}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    >
      {rowsData ?
        <>
          <TableHeaders headerGroups={headerGroups} />
          <TableBody
            {...getTableBodyProps()}
            rows={page}
            prepareRow={prepareRow}
            onClick={action}

          // maxHeight={"52.5vh"}
          />
          <TableControls
            pageOptions={pageOptions}
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageCount={pageCount}
          />
        </>
        : null}
    </div>
  );
};

export default TableMain;

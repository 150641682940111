const StatusFilter = ({ value, text, onChange }) => {
  return (
    <div style={{ marginLeft: "3px", float: "left" }}>
      < input
        type="checkbox"
        checked={value}
        onChange={onChange}
        style={{
          position: "relative",

          left: "6px",
          scale: "1.5",
          display: "inline",
        }
        }
      ></input >
      <p
        style={{
          position: "relative",
          left: "12px",
          display: "inline",
          marginLeft: "3px",
          lineHeight: "40px",
          fontSize: "1.2vmax",
          verticalAlign: "middle",
        }}
      >
        {text}
      </p>
    </div >
  );
};

export default StatusFilter;

export const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = [];
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal.push(charset.charAt(Math.floor(Math.random() * n)));
  }
  // check if retVal has no ASCII digits
  if (!retVal.some((char) => char >= "0" && char <= "9")) {
    retVal[Math.floor(Math.random() * length)] = String.fromCharCode(
      Math.floor(Math.random() * 10) + 48
    );
  }
  return retVal.join("");
};

import { LOCAL_SERVER_URL, SERVER_URL } from "../utils/constants";

const getAudiobookBorrowHistory = async (tokenSymbol, libAddress, username) => {

  console.log(tokenSymbol)
  console.log(libAddress)
  // libAddress = 'jjbqyitwzsvjzeez85a9rf8f4n3u6wk'
  // userAddress = 'pvkm32neqi685zg6ir3diwy4ixp5h4e9'

  const response = await fetch(
    `${SERVER_URL}/getAudiobookBorrowHistory?tokenSymbol=${tokenSymbol}&libAddress=${libAddress}&username=${username}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 400) {
    const { errors } = await response.json();
    console.log(errors)
    return null
  }
  return await response.json();
};
export default getAudiobookBorrowHistory;

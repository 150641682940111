import React from "react";
import Background from "../components/Background";
import LoginContainer from "../components/LoginViewComponents/LoginContainer";
import LogoAndHeaderTopBar from "../components/LogoAndHeaderTopBar";
import Footer from "../components/Footer";
class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="main-login-container">
        <LogoAndHeaderTopBar />
        <Background height="38vh" />
        <LoginContainer />
        <Footer />
      </div>
    );
  }
}

export default LoginView;
import { combineReducers } from "redux";

import dummy from "./dummy";
import audiobookiBooks from "./audiobookiBooksReducer";
import { authentication } from "./authenticatedReducer";
import showUnactiveEmployees from "./showUnactiveEmployeesReducer";
import showDeletedUsers from "./showDeletedUsersReducer";
const reducer = combineReducers({
  dummy,
  audiobookiBooks,
  authentication,
  showUnactiveEmployees,
  showDeletedUsers,
});

export default reducer;

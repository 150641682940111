import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import { withRouter } from "react-router";
import Button from "./Button";
import getEmployeesList from "../api/getEmployeesList";
import { useSelector } from "react-redux";
import changeEmployeeActiveStatus from "../api/changeEmployeeActiveStatus";
import { shortenTableEntry } from "../utils/formatter";
import { USER_STATUS } from "../utils/constants";

import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";
import TableSearch from "../components/TableSearch";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusFilter from "./StatusFilter";
import store from "../store";
import Spinner from "./Spinner/Spinner";

const LibraryEmployeesTable = ({ history }) => {
  const col = [
    {
      Header: "#", accessor: "idx",
      columnType: "col-sm-1",
    },
    {
      Header: "ID",
      accessor: "user_id",
      columnType: "col-sm-1",
    },
    {
      Header: "Użytkownik",
      accessor: "username",
      columnType: "col-sm-2",
      Cell: ({ value }) => {
        return shortenTableEntry(value, 30);
      },
    },
    { Header: "Nazwisko i imię", accessor: (row) => format_surname(row), columnType: "col-sm-2" },

    {
      Header: "Biblioteka",
      accessor: "industry",
      columnType: "col-sm-2",
    },
    {
      Header: "Email",
      accessor: "email",
      columnType: "col-sm-2",
      Cell: ({ value }) => {
        return shortenTableEntry(value, 35);
      },
    },

    {
      id: "employees-library-edit-button",
      columnType: "col-sm-1",
      Header: "Aktywny",
      accessor: "user_id",
      Cell: (row) => {
        return (
          <div className="container">
            <div className="row">
              <div className="col-3, float-left" >
                {row.row.original.is_active === USER_STATUS.Active ? (
                  <div style={{ color: "green", textAlign: "left", fontSize: "0.85rem", paddingRight: "15px", }}>tak</div>
                ) : (
                  <div style={{ color: "red", textAlign: "left", fontSize: "0.85rem", paddingRight: "15px" }}>nie</div>
                )}
              </div>
              <div className="col-3" style={{ paddingLeft: "0px" }}>
                <Button
                  text={
                    row.row.original.is_active === USER_STATUS.Active
                      ? "Dezaktywuj"
                      : "Aktywuj"
                  }
                  onClick={() => changeEmployeeStatus(row.value)}
                  className="btn-table"
                  styleOptions={
                    row.row.original.is_active === USER_STATUS.Active
                      ? {
                        padding: "0.25rem 0.5rem",
                        backgroundImage:
                          "linear-gradient(to right, #ee3c3c, #de6464)",
                        fontSize: "0.65rem",
                      }
                      : {
                        padding: "0.25rem 0.5rem",
                        backgroundImage:
                          "linear-gradient(to right, #31c13a ,#64de6c)",
                        fontSize: "0.65rem",
                      }
                  }
                />
              </div>
              {/* <div className="col-3, float-left" style={{ paddingLeft: "25px" }}>
                <Button
                  text="Edytuj"
                  onClick={() => editEmployee(row.value)}
                  className="btn-table"
                  styleOptions={{
                    padding: "0.25rem 0.75rem",
                    fontSize: "0.65rem",
                  }}
                />
              </div> */}
            </div>
          </div>
        );
      },
    },
  ];
  const [employees, setEmployees] = useState([]);
  const loggedUser = useSelector((state) => state.authentication.user);

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFiltered] = useState([]);
  const showUnactive = useSelector(
    (state) => state.showUnactiveEmployees.state
  );
  const [loading, setLoading] = useState(true);

  const getEmployees = async () => {
    let response;
    if (loggedUser.business_type === "audiobook_admin") {
      response = await getEmployeesList();
    } else if (loggedUser.business_type === "library") {
      response = await getEmployeesList(loggedUser.libId);
    }
    response = response.map((el, i) => ({ ...el, idx: i + 1 }));
    setEmployees(response);
    setFiltered(filterByStatus(response));
    setLoading(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const columns = useMemo(() => col, []);
  const data = useMemo(() => filteredData, [filteredData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    { columns, data, initialState: { pageSize: 16 }, autoResetSortBy: false },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  const openTransaction = (employeeID) => {
    // console.log("employees-library clicked");
    console.log("employees-library clicked:", employeeID);
    editEmployee(employeeID);
  };

  const editEmployee = (id) => {
    history.push(`/employees-library/edit/${id}`);
  };
  const changeEmployeeStatus = async (id) => {
    console.log("State in the function: ", showUnactive);
    let response = await changeEmployeeActiveStatus(id);
    if (response) {
      getEmployees();
    }
  };
  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const changeStatusFilter = () => {
    if (showUnactive === false) store.dispatch({ type: "SHOW_ALL_EMPLOYEES" });
    else store.dispatch({ type: "SHOW_ACTIVE_EMPLOYEES" });
  };

  const filterByStatus = (data) => {
    if (showUnactive === false) {
      const filtered = data.filter((el) => el.is_active === USER_STATUS.Active);
      return filtered;
    } else {
      return data;
    }
  };

  useEffect(() => {
    console.log("Filter unactive changed to: ", showUnactive);
  }, [showUnactive]);

  useEffect(() => {
    if (searchInput.length > 0) {
      let results = employees.filter((value) => {
        if (value.user_id && value.firstname && value.surname &&
          value.username && value.industry && value.email)
          return (
            value.user_id.toString().includes(searchInput) ||
            value.username.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.firstname.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.surname.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.industry.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.email
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          );
        else return "";
      });
      setFiltered(filterByStatus(results));
    } else setFiltered(filterByStatus(employees));
  }, [searchInput, showUnactive]);

  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text={
            <div style={{ display: "flex", color: "#f4f4f4" }}>
              Dodaj pracownika
            </div>
          }
          styleOptions={{
            float: "right",
            backgroundColor: "white",
            backgroundImage: "none",
            height: "100%",
            color: "black",
          }}
          onClick={() => history.push("/employees-library/add")}
        />
        <TableSearch
          name="searchInputField"
          value={searchInput || ""}
          onChange={handleChange}
        />
        <StatusFilter
          text={"Pokaż nieaktywnych"}
          value={showUnactive}
          onChange={changeStatusFilter}
        />
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={"auto"}
        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {!loading ?
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}
                onClick={openTransaction}
                maxHeight={"67vh"}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
            : <div style={{ textAlign: "-webkit-center" }}>
              <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
            </div>
          }
        </div>
      </PlainContainer>
    </div>
  );
};

function format_surname(row) {
  return row.surname + " " + row.firstname;
}

export default withRouter(LibraryEmployeesTable);

import Button from "../Button";
import TableEntryCell from "../TableEntryCell";

const AwaitingFragmentTableEntry = ({ user, sizes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        float: "right",
        height: "3.5vh",
      }}
    >
      <TableEntryCell value={user.libraryCardNumber} size={sizes[0]} />
      <TableEntryCell value={user.lastName} size={sizes[1]} />
      <TableEntryCell value={user.firstName} size={sizes[2]} />
      <TableEntryCell
        size={sizes[3]}
        value={
          <div>
            <Button
              styleOptions={{
                width: "100px",
                display: "inline-block",
                height: "25px",
                lineHeight: "25px",
                padding: "0px",
                backgroundImage: "linear-gradient(to right, #31c13a ,#64de6c )",
              }}
              text="Aktywuj"
            ></Button>{" "}
            <Button
              styleOptions={{
                width: "100px",
                display: "inline-block",
                height: "25px",
                lineHeight: "25px",
                padding: "0px",
                backgroundImage: "linear-gradient(to right, #ee3c3c, #de6464)",
              }}
              text="Odrzuć"
            >
              Decline
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default AwaitingFragmentTableEntry;

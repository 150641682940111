import "./RegisterContainer.css";
// import { QRCode } from "react-qr-svg";
const RegisterContainer = () => {
  return (
    <div className="login-view-qr">
      <div
        style={{
          width: "20vw",
          height: "20vw",
          backgroundColor: "rgb(157, 78, 145)",
          lineHeight: "20vw",
          color: "white",
          margin: "0 2.5em"
        }}
      >
        {/* <QRCode
                bgColor="white"
                fgColor="#9d4e91"
                level="Q"
                style={{ width: "45%" }}
                value={this.props.loginQRCodeString}
              /> */}
        Kod QR
      </div>
    </div>
  );
};

export default RegisterContainer;

import React from "react";

const PlainContainer = ({
  children,
  widthInVw,
  heightInVh,
  customStyles = {},
}) => (
  <div
    style={{
      width: `${widthInVw}vw`,
      height: `${heightInVh}vh`,
      borderRadius: "5px",
      paddingBottom: "40px",
      boxShadow: "2px 9px 15px 3px rgba(0,0,0,0.16)",
      backgroundColor: "white",
      marginTop: "10px",
      ...customStyles,
    }}
  >
    {children}
  </div>
);

export default PlainContainer;

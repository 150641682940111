const SingleUserInformationErrors = ({ errors }) => {
  return (
    <div
      className="row"
      style={{
        alignItems: "center",
        padding: "5px 0px 10px",
        fontSize: "15px",
        color: "red",
      }}
    >
      {errors.nick && <div className="col"> {errors.nick.message}</div>}
      {errors.firstName && (
        <div className="col"> {errors.firstName.message}</div>
      )}
      {errors.lastName && <div className="col"> {errors.lastName.message}</div>}
      {errors.email && <div className="col"> {errors.email.message}</div>}
      {errors.birthDate && (
        <div className="col"> {errors.birthDate.message}</div>
      )}
      {errors.libraryCardNumber && (
        <div className="col">{errors.libraryCardNumber.message}</div>
      )}
      {errors.publicKey && (
        <div className="col"> {errors.publicKey.message}</div>
      )}
    </div>
  );
};

export default SingleUserInformationErrors;

import AwaitingFragmentTableHeader from "./AwaitingFragmentTableHeader";

const AwaitingFragmentTableHeaders = ({ names, sizes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        height: "3vh",
        alignItems: "flex-end",
        width: "100%",
        float: "right",
      }}
    >
      {names.map((name, index) => {
        return (
          <AwaitingFragmentTableHeader
            name={name}
            size={sizes[index]}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default AwaitingFragmentTableHeaders;

import React from "react";

const FragmentDescription = ({ description, styleOptions = {} }) => (
  <div
    style={{
      color: "white",
      fontSize: "calc(0.5vw + 12px)",
      letterSpacing: "0.3px",
      borderBottom: "solid rgba(96, 78, 151, 0.4) 1px",
      // width: "94%",
      // marginLeft: "auto",
      // marginRight: "auto",
      // marginBottom: "20px",
      ...styleOptions,
    }}
  >
    {description}
  </div>
);

export default FragmentDescription;

import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
const composeEnhancers = process.env.NODE_ENV !== 'production'
  && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: false,
  })
  : compose

const enhancers = composeEnhancers(
  applyMiddleware(thunk)
)
const store = createStore(reducer, enhancers)

export default store
export const { getState } = store

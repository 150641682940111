import { useEffect, useState } from "react";

import Button from "./Button";
// import SingleUserInformationEditable from "./SingleUserInformationEditable";
import SingleUserInformationNonEditable from "./SingleUserInformationNonEditable";
import SingleUserInformationNotes from "./SingleUserInformationNotes";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SingleUserInformationErrors from "./SingleUserInformationErrors";

const userSchema = yup.object().shape({
  nick: yup.string().required("Podaj nazwę użytkownika."),
  firstName: yup.string().required("Podaj imię."),
  lastName: yup.string().required("Podaj nazwisko."),
  libraryCardNumber: yup.string().required("Podaj number karty bibliotecznej."),
  birthDate: yup.string().required("Podaj datę urodzenia."),
  email: yup.string().required("Podaj email.").email(),
  publicKey: yup.string().required("Podaj adres DHC."),
});

const SingleUserInformation = ({ user }) => {
  const [editing, setEditing] = useState(false);
  // const [user, setUser] = useState(userData);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(userSchema),
  });

  useEffect(() => {
  }, [user]);

  return (
    <div
      className="container"
      style={{
        textAlign: "center",
        maxWidth: "100%",

      }}
    >

      <table width="100%" >
        <tr><td width="33%"><div style={{
          textAlign: "left",
          fontSize: "3vh",
          position: "relative",
          top: "2vh",
        }}>KARTA CZYTELNIKA</div><p /></td></tr>
        <tr><td colSpan={2}><div style={{
          fontSize: "3vh",
          backgroundColor: "#eee",
          position: "relative",
          top: "2vh",
          boxShadow: "4px 4px 3px 0px rgba(0,0,0,0.2)",
        }}> <b>{user?.name} {user?.surname}</b></div></td>
        </tr>
      </table>
      <SingleUserInformationNonEditable
        user={user}
        isVisible={editing}


      />

      {/* <SingleUserInformationEditable
        user={user}
        isVisible={editing}
        onClick={toggleEditing}
        // editFirstName={editFirstName}
        // editLastName={editLastName}
        // editLibraryCardNumber={editLibraryCardNumber}
        // editNick={editNick}
        // editEmail={editEmail}
        // editBirthDate={editBirthDate}
        // editPublicKey={editPublicKey}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
      /> */}
      <SingleUserInformationNotes user={user} />
      {
        // <Button
      }
      <SingleUserInformationErrors errors={errors} user={user} />
      {/* <Button
        // onClick={toggleEditing}
        onClick={() =>
          window.alert(
            "Tutaj w przyszłości możliwe będzie edytowanie danych użytkowników"
          )
        }
        text={<FontAwesomeIcon icon={faEdit} />}
        styleOptions={{
          maxWidth: "40px",
          width: "40px",
          padding: "9px 7px 9px 9px",
          position: "absolute",
          top: "5px",
          right: "5px",
          display: editing ? "none" : "",
        }}
      /> */}
    </div>
  );
};

export default SingleUserInformation;

const TableEntryCell = ({ value, size, customStyles = {} }) => {
  return (
    <div
      style={{
        width: size,
        textAlign: "center",
        height: "3.5vh",
        lineHeight: "3.5vh",
        ...customStyles,
      }}
    >
      {value}
    </div>
  );
};

export default TableEntryCell;

import React from "react";
import background from "../icons/background.svg";

const Background = ({ height }) => (
  <div
    style={{
      zIndex: -3,
      position: "absolute",

      top: "-5px",
      width: "calc(100vw)",
      height,
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "left bottom",
    }}
  />
);

export default Background;

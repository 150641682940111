import { LOCAL_SERVER_URL, SERVER_URL } from "../utils/constants";

const signAndSendSimpleTransaction = async ({ from, to }) => {


  const response = await fetch(
    `${SERVER_URL}/signAndSendSimpleTransaction?from=${from}&to=${to}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status !== 200) {
    const { errors } = await response.json();
    return errors
  }
  return await response.json();
};
export default signAndSendSimpleTransaction;

const SingleAudiobookInformationHeaders = () => {
  return (
    <div
      className="row"
      style={{
        textAlign: 'left',
        fontSize: "2vh",
        fontWeight: "bold",
        padding: "1.0vh 0px",
      }}
    >
      <div className="col-sm-2">Liczba kopii</div>
      <div className="col-sm-2">Na półce</div>
      <div className="col-sm-2">Wypożyczeń</div>

    </div >
  );
};

export default SingleAudiobookInformationHeaders;

import TableRow from "./TableRow";
const TableBody = ({ rows, prepareRow, onClick }) => {
  return (
    <div className="table-body-container"
      style={{}}>
      {rows.map((row, index) => {
        prepareRow(row);
        return <TableRow row={row} onClick={onClick} key={index} />;
      })}
    </div>
  );
};

export default TableBody;

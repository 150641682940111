const OverTableButtons = ({
  children,
  widthInVw,
  heightInVh,
  customStyles = {},
}) => {
  return (
    <div
      style={{
        width: `${widthInVw}vw`,
        height: `${heightInVh}vh`,
        borderRadius: "5px",
        // boxShadow: "2px 9px 15px 3px rgba(0,0,0,0.16)",

        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        ...customStyles,
      }}
    >
      {children}
    </div>
  );
};

export default OverTableButtons;

import React from "react";
import DHunterLogo from "./DHunterLogo";
import audiobookLogo from "../icons/logo-audiobook-blue.png";

import { APP_VERSION } from '../utils/constants'
const Footer = () => (
  <div  >
    <footer className="fixed-bottom ml-1" style={{ position: "relative", top: "92vh", zIndex: "-1" }} >

      <div align="center">
        <table /*border="1px solid black"+*/>
          {/* <tr ><td colSpan={3} text-align="center"><div align="center" style={{
            fontSize: "9pt",
          }}></div></td></tr> */}
          <tr>
            <td><img src={audiobookLogo} width="auto" height="50" title="Audiobook.pl" alt="Audiobook.pl" /></td><td width="30"></td><td><DHunterLogo /></td></tr>
        </table>
      </div>
      <div style={{ position: "relative", top: "-5vh", left: "1.5%" }}>{APP_VERSION}</div>
    </footer >

  </div >
);

export default Footer;
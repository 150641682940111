import React, { useEffect, useState } from "react";
import getBorrowedHistory from "../api/getBorrowedHistory";
import { getDateFromDateTime, getTimeFromDateTime } from "../utils/time";
import TableMain from "./TableMain";
import { withRouter } from "react-router-dom";
import Spinner from "./Spinner/Spinner";

const SingleUserBorrowHistory = ({ history, user }) => {

  const [userBorrowHistoryList, setUserBorrowHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const col = [
    { Header: "#", accessor: "idx", columnType: "col-sm-1" },
    {
      Header: "ID ",
      accessor: "token_symbol",
      columnType: "col-sm-1"
    },
    {
      Header: "Tytuł",
      accessor: "nazwa",
      columnType: "col-sm-6",
      Cell: ({ value }) => {
        return decodeURIComponent(value).replace(/\+/g, " ");
      },
    },
    {
      Header: "Data wypożyczenia",
      accessor: "borrow_date",
      columnType: "col-sm-2",
      Cell: ({ value }) => {
        return getDateFromDateTime(value) + " " + getTimeFromDateTime(value);
      },
    },
    {
      Header: "Data oddania",
      accessor: "return_date",
      columnType: "col-sm-2",
      Cell: ({ value }) => {
        if (value) {
          return getDateFromDateTime(value) + " " + getTimeFromDateTime(value);
        } else {
          return "Pozycja wypożyczona"
        }
      },
    },
  ];

  const openTransaction = (audiobookID) => {

    history.push(`${process.env.PUBLIC_URL}/audiobook/${audiobookID}`);

  };

  useEffect(() => {

    const getBorrHist = async (libAddress, userAddress) => {
      return await getBorrowedHistory(libAddress, userAddress).then(res => {
        if (res) {
          res = res.map((el, i) => ({ ...el, idx: i + 1 }));
          setUserBorrowHistory(res)
        }
        setLoading(false);
      })
    }

    if (Object.getOwnPropertyNames(user).length !== 0) {
      getBorrHist(user?.assigned_library, user?.public_key)
    }

  }, [user])


  return (
    <>
      {
        loading ?
          <div style={{ textAlign: "-webkit-center" }}>
            <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
          </div>
          :
          !userBorrowHistoryList || userBorrowHistoryList.length === 0
            ? <p style={{ height: "35.6vh", fontSize: "30px", lineHeight: "35.6vh" }} className="text-center mt-3">Ten czytelnik jeszcze niczego nie wypożyczył</p>
            : <TableMain columns={col} rowsData={userBorrowHistoryList} action={openTransaction} />
      }
    </>
  );
};

export default withRouter(SingleUserBorrowHistory);

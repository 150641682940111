import React from "react";

const TopBarButton = ({ iconSrc, label, width, ...props }) => (
  <div
    {...props}
    style={{
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "2vw",
    }}
  >
    <div
      style={{
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={iconSrc}
        alt=""
        style={{
          width: `${width}`,
        }}
      />
    </div>
    <div
      style={{
        marginTop: "5px",
        color: "black",
        letterSpacing: "0.3px",
        fontSize: "calc(0.5vw + 5px)",
      }}
    >
      {label}
    </div>
  </div>
);

export default TopBarButton;

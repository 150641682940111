import { LOCAL_SERVER_URL, SERVER_URL } from "../utils/constants";
import parseAuthor from "../utils/reformatAuthor";
const getAudiobooksByBuisnessType = async (address, username) => {

  //get all audiobooks if address is not passed (for admin)
  //if passed (for library or libEmployee), get audiobooks assigned to this address

  const response = (address === undefined) ?
    await fetch(
      `${SERVER_URL}/getAllAudiobooks`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }) :
    await fetch(
      `${SERVER_URL}/getAudiobooksFromAddress?address=${address}&username=${username}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });

  if (response.status === 400) {
    const { errors } = await response.json();
    return { errors };
  }
  let respJson = await response.json();
  // parse autor in respJson
  for (let i = 0; i < respJson.length; i++) {
    respJson[i].autor = parseAuthor(respJson[i].autor);
  }
  return respJson;
};

export default getAudiobooksByBuisnessType;

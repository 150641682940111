export const getTimeFromDateTime = (datetime) => {
    const dt = new Date(datetime)
    return ((dt.getHours() < 10)?"0":"") + dt.getHours() +":"+ ((dt.getMinutes() < 10)?"0":"") + dt.getMinutes();
}

export const getDateFromDateTime = datetime => {

    var today = new Date(datetime);
    var dd = today.getDate();

    var mm = today.getMonth() + 1; 
    var yyyy = today.getFullYear();

    if(dd<10) dd = '0' + dd;
    if(mm<10) mm = '0' + mm;

    return dd + '-' + mm + '-' + yyyy;
}

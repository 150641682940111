import { shortenTableEntry } from "../utils/formatter";
import { useEffect, useState } from "react";
import Spinner from "./Spinner/Spinner";

const SingleAudiobookInformationNonEditable = ({ audiobook }) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (audiobook) {
      setLoading(false);
    }
  }, [audiobook]);

  return (
    <div
      className="row"
      style={{
        alignItems: "center",
        borderBlockColor: "black",
        overflowWrap: "anywhere",
        padding: loading ? "20px 0px 10px" : "0px 0px",
        fontSize: "1.8vh",
        textAlign: "left"
      }}
    >
      {!loading ?
        <>
          <div className="col-sm-2" style={{}}>{audiobook[0].RazemInOut}</div>
          <div className="col-sm-2" style={{}}>{audiobook[0].Ballance}</div>
          <div className="col-sm-2" style={{}}>{audiobook[0].sumaBorrow}</div>
        </>
        : <Spinner customStyles={{ fontSize: "5px", left: "50%" }} />
      }
    </div>
  );
};

export default SingleAudiobookInformationNonEditable;

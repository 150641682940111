import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
const TableHeader = ({ headerGroup }) => {
  return (
    <div
      className="row"
      style={{
        textAlign: "left",
        fontSize: "2vh",
        fontWeight: "bolder",
        color: "rgb(157, 78, 145)",
        padding: "0.8vh 0"

      }}
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => (
        <div
          className={`${column.columnType} table-header `}
          {...column.getHeaderProps(
            column.getSortByToggleProps({
              title:
                column.Header !== "" ? `Sortuj dane wg: ${column.Header}` : "",
            })
          )}
        >
          {column.render("Header")}
          {column.Header !== "" ? (
            column.isSorted ? (
              column.isSortedDesc ? (
                <FontAwesomeIcon
                  icon={faSortDown}
                  style={{
                    marginLeft: "10px",
                    position: "absolute",
                    top: "5px",
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSortUp}
                  style={{
                    marginLeft: "10px",
                    position: "absolute",
                    top: "5px",
                  }}
                />
              )
            ) : (
              <FontAwesomeIcon
                icon={faSort}
                style={{ marginLeft: "10px", position: "absolute", top: "5px" }}
              />
            )
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;

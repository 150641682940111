import PlainContainer from "../PlainContainer";
import FragmentDescription from "../FragmentDescription";
import AudiobooksListFragmentTable from "./AudiobooksListFragmentTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const AudiobooksListFragment = ({ history }) => {
  return (
    <PlainContainer
      widthInVw={95}
      heightInVh={24}
      customStyles={{
        top: "74vh",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingBottom: "0px",
      }}
    >
      <FragmentDescription
        description="Lista Audiobooków w Bibliotece"
        styleOptions={{
          fontSize: "calc(0.75vw + 5px)",
          textAlign: "center",
          color: "black",
        }}
      />
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        style={{
          position: "absolute",
          right: "3px",
          top: "3px",
        }}
        className="open-new-view-button"
        onClick={() => {
          history.push(`${process.env.PUBLIC_URL}/audiobooks`);
        }}
      />
      <AudiobooksListFragmentTable
        names={[
          "Tytuł",
          "Autor",
          "Ilość egzemplarzy",
          "Suma wypożyczeń",
          "Ilość osób oczekujących",
        ]}
        sizes={["16vw", "16vw", "16vw", "16vw", "16vw"]}
      />
    </PlainContainer>
  );
};

export default withRouter(AudiobooksListFragment);

import * as EC from "elliptic";
import sha256 from "js-sha256";
import hexToArrayBuffer from "hex-to-array-buffer";
import BaseX from "base-x";
import { Buffer } from "buffer/";
import { BASE_32_CHAR_SET } from "../utils/constants";
import { int32ToUint8Array, float64ToUint8Array } from "./memory";

// eslint-disable-next-line new-cap
const ec = new EC.ec("secp256k1");
export const base32 = BaseX(BASE_32_CHAR_SET);

export function getPublicFromPrivateKey(privateKey) {
  const keyPair = ec.keyFromPrivate(privateKey, "hex");
  return keyPair.getPublic().encode("hex");
}

export function getAddressFromPrivateKey(privateKey) {
  const publicKey = getPublicFromPrivateKey(privateKey);
  const hashedPubKey = sha256(publicKey);
  const trimmedHashedPubKey = hashedPubKey.substr(0, 40);
  const arrayBuffer = hexToArrayBuffer(trimmedHashedPubKey);

  return base32.encode(Buffer.from(arrayBuffer));
}

export function generatePrivateKey() {
  const pair = ec.genKeyPair();
  return pair.getPrivate("hex");
}

function signMessage(msgByteArr, privateKey) {
  const msgHash = sha256.update(msgByteArr).array();
  const keyPair = ec.keyFromPrivate(privateKey, "hex");

  const derArray = keyPair.sign(msgHash).toDER();

  return Buffer.from(derArray).toString("hex");
}

export function signSimpleTransaction(
  {
    type,
    from,
    to,
    amount,
    tokenId,
    signatureTimestamp,
    locationLat,
    locationLon,
  },
  privateKey
) {
  const payloadBytesArrays = [
    base32.decode(this.from), // 20 bytes
    base32.decode(this.to), // 20
    this.nonce ? base32.decode(this.nonce) : new Uint8Array(32), // 32
    int32ToUint8Array(this.amount), // 4
    int32ToUint8Array(this.tokenId), // 4
    float64ToUint8Array(this.locationLat), // 8
    float64ToUint8Array(this.locationLon), // 8
    float64ToUint8Array(this.signatureTimestamp), // 8
  ];

  const msgByteSize = 20 + 20 + 32 + 4 + 4 + 8 + 8 + 8;
  const msgByteArr = new Uint8Array(msgByteSize);

  let totalByteSizeOfPayload = 0;

  payloadBytesArrays.forEach((byteArr) => {
    msgByteArr.set(byteArr, totalByteSizeOfPayload);
    totalByteSizeOfPayload += byteArr.length;
  });

  if (totalByteSizeOfPayload !== msgByteSize) {
    throw new Error(
      `Real payload size ${totalByteSizeOfPayload} does not match expected ${msgByteSize}`
    );
  }

  return signMessage(msgByteArr, privateKey);
}

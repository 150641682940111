const TableSearch = (props) => {
  return (
    <input
      name = {props.name}
      value={props.value}
      onChange={props.onChange}
      className="table-search"
      placeholder="Wyszukaj..."
    ></input>
  );
};

export default TableSearch;

import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Router from "./Router";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { SnackbarProvider } from 'notistack';

import "./theme/App.scss";

class App extends React.Component {
  render() {
    console.log("REACT_APP_SERVER_URL=", process.env.REACT_APP_SERVER_URL);
    console.log("NODE_ENV=", process.env.NODE_ENV);
    console.log("GCLOUD_PROJECT=", process.env.GCLOUD_PROJECT);
    console.log("FIREBASE_TARGET=", process.env.FIREBASE_TARGET);
    console.log("PUBLIC_URL=", process.env.PUBLIC_URL);
    console.log("process.env", process.env);
    return (
      <Provider store={store}>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>

      </Provider>
    );
  }
}
export default App;

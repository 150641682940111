import { useEffect, useState } from "react";
import Spinner from "./Spinner/Spinner";
import Button from "./Button";

import { enqueueSnackbar } from 'notistack';
const SingleUserInformationNonEditable = ({ user, onClick, isVisible }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (

    <div
      className="row"
      style={{
        // alignItems: "center",
        padding: loading ? "10px 0px 10px" : "0px 0px 10px",
        fontSize: "2vh",
        display: isVisible ? "none" : "",
        whiteSpace: "pre-wrap",
        position: "relative",
        top: "2vh",
      }}
    >
      {!loading ?
        <>

          <div className="col-sm-3" style={{ padding: "0.6rem 0.4rem" }}>
            <div className="row"><b>Mail:</b></div>
            <div className="row">{user?.email}</div>
          </div>
          {/* <div className="col" style={{ padding: "0.6rem 0.4rem" }}>
            <div className="row"><h6><b>Data ur.:</b></h6></div>
            <div className="row">{user?.date_of_birth}</div>
          </div> */}

          <div className="col-sm-3" style={{ padding: "0.6rem 0.4rem" }}>
            <div className="row"><b>Telefon:</b></div>
            <div className="row">{format_phone(user?.phone_number)}</div>
          </div>
          <div className="col-sm-3" style={{ padding: "0.6rem 0.4rem" }}>
            <div className="row"><b>Nr karty:</b></div>
            <div className="row">{user?.lib_card_number}</div>
          </div>
          <div className="col-sm-2" style={{ padding: "0.6rem 0.4rem" }}>
            <div className="row" style={{ alignItems: "baseline" }} >
              <b>Adres:   </b>
              <Button text="Skopiuj"
                styleOptions={{
                  padding: "0.1rem 0.2rem",
                  background: "black",
                  fontSize: "1.4vh",
                  alignItems: "center",
                  margin: "0.2em"
                }}
                onClick={() => {
                  navigator.clipboard.writeText(user?.public_key)
                  enqueueSnackbar('Skopiowano do schowka')
                }}
              />
            </div>
            <div className="row">{format_address(user?.public_key)}</div>
          </div>
        </>
        : <Spinner customStyles={{ fontSize: "5px", left: "50%" }} />
      }
    </div >
  );
};
function format_address(addr) {
  const noChars = 6;
  if (addr) {
    return addr.substr(0, noChars) + "....." + addr.substr(addr.length - noChars, noChars);
  } else { return "" };

}
function format_email(email) {
  if (email) {
    const parts = email.split('@')
    if (parts.length === 2) {
      let ret = parts[0] + "@\n" + parts[1]
      return ret
    }
    else { return email }
  }
}
function format_phone(phone) {
  if (phone && phone.startsWith("+48")) {
    phone = phone.replace("+48", "");
  }
  return phone
}


export default SingleUserInformationNonEditable;

import React, { useMemo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";
import TableControls from "./TableControls";
import getUsersList from "../api/getUsersList";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import TableSearch from "../components/TableSearch";
import OverTableButtons from "../components/OverTableButtons";
import PlainContainer from "../components/PlainContainer";
import Button from "../components/Button";
import { getDateTime } from "../utils/formatter";
import StatusFilter from "./StatusFilter";
import store from "../store";
import Spinner from "./Spinner/Spinner";
import { USER_STATUS } from "../utils/constants";

const col = [
  { Header: "#", accessor: "idx", columnType: "col-sm-1" },
  { Header: "ID", accessor: "id", columnType: "col-sm-1" },
  {
    Header: "Nr karty",
    accessor: (row) => format_card_number(row),
    columnType: "col-sm-2",
  },

  { Header: "Nazwisko i imię", accessor: (row) => format_surname(row), columnType: "col-sm-3" },
  {
    Header: "Status",
    id: "is_active",
    accessor: (row) => (row.is_active),
    columnType: "col-sm-1",
    Cell: ({ value }) => {
      return value === USER_STATUS.Active ? (
        <div style={{ color: "green" }}>aktywny</div>
      ) : value === USER_STATUS.Deleted ? (
        <div style={{ color: "blue" }}>usunięty</div>
      ) : (
        < div style={{ color: "red" }
        }> nieaktywny</div >
      );
    },
  },
  {
    Header: "Ostatnia zmiana",
    accessor: "update_time",
    columnType: "col-sm-2",
    Cell: ({ value }) => {
      return getDateTime(value);
    },
  },
];

const UsersTable = ({ history }) => {
  var [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFiltered] = useState([]);
  const showDeleted = useSelector(
    (state) => state.showDeletedUsers.state
  );
  const [loading, setLoading] = useState(true);

  const loggedUser = useSelector((state) => state.authentication.user);

  const getUsers = async () => {
    let response = await getUsersList(loggedUser.libAddress, loggedUser.username);
    response = response.map((el, i) => ({ ...el, idx: i + 1 }));
    setUsers(response);
    setFiltered(filterByStatus(response));
    setLoading(false);
  };

  useEffect(() => {
    getUsers();

  }, []);

  const columns = useMemo(() => col, []);
  const data = useMemo(() => filteredData, [filteredData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageOptions,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns, data, initialState: {
        pageSize: 18,
        sortBy: [{ id: "update_time", desc: "true" }],
      },
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );
  const { pageIndex } = state;

  const openUser = (userID) => {
    history.push(`${process.env.PUBLIC_URL}/user/${userID}`);
  };

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const changeStatusFilter = () => {
    if (showDeleted === false) store.dispatch({ type: "DONT_SHOW_DELETED_USERS" });
    else store.dispatch({ type: "SHOW_DELETED_USERS" });
  };

  const filterByStatus = (data) => {
    if (showDeleted === false) {
      const filtered = data.filter((el) => el.is_active !== USER_STATUS.Deleted);
      return filtered;
    } else {
      return data;
    }
  };

  useEffect(() => {
  }, [showDeleted]);

  useEffect(() => {
    if (searchInput.length > 0) {
      let results = users.filter((value) => {
        if (
          (value.name &&
            value.lib_card_number &&
            value.surname &&
            value.is_active) !== undefined
        )
          return (
            value.lib_card_number.toString().includes(searchInput) ||
            value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            value.surname.toLowerCase().includes(searchInput.toLowerCase())
          );
      });
      setFiltered(filterByStatus(results));
    } else setFiltered(filterByStatus(users));
  }, [searchInput, showDeleted]);

  return (
    <div>
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text=
          "Odśwież"

          isDisabled={false}
          styleOptions={{
            float: "right",
            boxShadow: "2px 4px 18px -15px rgba(255,0,0,0.31)",
          }}
          onClick={() => {
            setLoading(true);
            getUsers();
          }}
        />
        <Button
          text={
            <div style={{ display: "flex" }}>
              Dodaj uzytkownika{" "}
              <FontAwesomeIcon
                icon={faUserPlus}
                style={{ alignSelf: "center", marginLeft: "6px" }}
              />
            </div>
          }
          isDisabled={false}
          styleOptions={{
            display: "none",
            float: "right",
            backgroundColor: "white",
            backgroundImage: "none",
            height: "100%",
            color: "black",
            marginLeft: "3px",
            fontSize: "15px",
          }}
          onClick={() => {
            // history.push(`${process.env.PUBLIC_URL}/dashboard`);
            window.alert("Dodaje czytelnika");
          }}
        />
        <Button
          text={
            <div style={{ display: "flex" }}>
              Importuj czytelników{" "}
              <FontAwesomeIcon
                icon={faFileImport}
                style={{ alignSelf: "center", marginLeft: "6px" }}
              />
            </div>
          }
          isDisabled={false}
          styleOptions={{
            display: "none",
            float: "right",
            backgroundColor: "white",
            backgroundImage: "none",
            height: "100%",
            color: "black",
            fontSize: "15px",
          }}
          onClick={() => {
            // history.push(`${process.env.PUBLIC_URL}/dashboard`);
            window.alert("Importuje czytelników");
          }}
        />
        <TableSearch
          name="searchInputField"
          value={searchInput || ""}
          onChange={handleChange}
        />

        <StatusFilter
          text={"Pokaż usuniętych"}
          value={showDeleted}
          onChange={changeStatusFilter}
        />

      </OverTableButtons>
      <PlainContainer
        widthInVw={95}

        customStyles={{
          top: "19vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <div
          className="container"
          {...getTableProps()}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          {!loading ?
            <>
              <TableHeaders headerGroups={headerGroups} />
              <TableBody
                {...getTableBodyProps()}
                rows={page}
                prepareRow={prepareRow}
                onClick={openUser}
              // maxHeight={"67vh"}
              />
              <TableControls
                pageOptions={pageOptions}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                pageCount={pageCount}
              />
            </>
            : <div style={{ textAlign: "-webkit-center" }}>
              <Spinner customStyles={{ fontSize: "30px", margin: "40px" }} />
            </div>
          }
        </div>
      </PlainContainer>
    </div>
  );
};
function format_card_number(row) {
  if (row.name.slice(-8) === "StopCafe") {

    const number = row.lib_card_number;
    const noChars = 8;
    if (number) {
      return number.substr(0, noChars) + "....." + number.substr(number.length - noChars, noChars);
    }
  } else { return row.lib_card_number; };
}
function format_surname(row) {
  if (row.name.slice(-8) === "StopCafe") {
    const noChars = 8;
    if (row.surname) {
      return row.surname.substr(0, noChars) + "....." + row.surname.substr(row.surname.length - noChars, noChars);
    }
  } else { return row.surname + " " + row.name; };
}


export default withRouter(UsersTable);

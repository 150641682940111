import * as types from "../redux/types/types";

const state = (() => {
  const cachedUserStatus = localStorage.getItem("usersStatusFilter");
  if (cachedUserStatus) {
    return JSON.parse(cachedUserStatus);
  } else {
    localStorage.setItem("usersStatusFilter", false);
    return false;
  }
})();

const initialStore = {
  state,
};

const showDeletedUsers = (store = initialStore, action) => {
  switch (action.type) {
    case types.SHOW_DELETED_USERS:
      localStorage.setItem("userStatusFilter", false);
      return { ...store, state: false };
    case types.DONT_SHOW_DELETED_USERS:
      localStorage.setItem("userStatusFilter", true);
      return { ...store, state: true };
    default:
      return store;
  }
};

export default showDeletedUsers;

import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faBackward } from "@fortawesome/free-solid-svg-icons";
const TableControls = ({
  pageOptions,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  customStyles,
  pageIndex,
  gotoPage,
  pageCount,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "30px",
        textAlign: "center",
        margin: "1rem",
        ...customStyles,
      }}
    >
      <Button
        text={<FontAwesomeIcon icon={faBackward} />}
        styleOptions={{
          display: "inline",
          padding: "0.25rem 0.5rem",
          marginRight: "1rem"
        }}
        onClick={() => gotoPage(0)}
        isDisabled={!canPreviousPage}
      />
      <Button
        text="Poprzednia"
        styleOptions={{
          display: "inline",
          padding: "0.25rem 0.5rem",
          marginRight: "1rem",
          minWidth: "100px"
        }}
        onClick={() => previousPage()}
        isDisabled={!canPreviousPage}
      />
      {`Strona ${pageIndex + 1} z ${pageOptions.length ? pageOptions.length : 1}`}
      <Button
        text="Następna"
        styleOptions={{
          display: "inline",
          padding: "0.25rem 0.5rem",
          marginLeft: "1rem",
          minWidth: "100px"
        }}
        onClick={() => nextPage()}
        isDisabled={!canNextPage}
      />
      <Button
        text={<FontAwesomeIcon icon={faForward} />}
        styleOptions={{
          display: "inline",
          padding: "0.25rem 0.5rem",
          marginLeft: "1rem"
        }}
        onClick={() => gotoPage(pageCount - 1)}
        isDisabled={!canNextPage}
      />
    </div>
  );
};

export default TableControls;

import TableEntryCell from "../TableEntryCell";

const ActiveFragmentTableEntry = ({ user, sizes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        float: "right",
        height: "3.5vh",
      }}
    >
      <TableEntryCell value={user.libraryCardNumber} size={sizes[0]} />
      <TableEntryCell value={user.lastName} size={sizes[1]} />
      <TableEntryCell value={user.firstName} size={sizes[2]} />
      {user.state === "active" ? (
        <TableEntryCell
          value={"aktywny"}
          size={sizes[3]}
          customStyles={{ color: "green" }}
        />
      ) : (
        <TableEntryCell
          value={"nieaktywny"}
          size={sizes[3]}
          customStyles={{ color: "red" }}
        />
      )}
    </div>
  );
};

export default ActiveFragmentTableEntry;

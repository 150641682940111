import React, { Component } from "react";
import PlainContainer from "../PlainContainer";
import "./RegisterContainer.css"
import VerticalLine from "../VerticalLine";
import RegisterCredentials from "./RegisterCredentials";
import RegisterQrCode from "./RegisterQRCode";

class RegisterContainer extends Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          zIndex: 100,
          backgroundImage:
            "linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35))",
        }}
      >
        <PlainContainer
          widthInVw={50}
          heightInVh={65}
          customStyles={{
            top: "15vh",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <>
            <div className="login-view-form-qr-container">
              <RegisterCredentials />
              <VerticalLine />
              <RegisterQrCode />
            </div>
          </>
        </PlainContainer>
      </div>
    );
  }
}

export default RegisterContainer;

import Background from "../components/Background";
import NavTopBar from "../components/NavTopBar";
import PlainContainer from "../components/PlainContainer";
import SingleAudiobookInformation from "../components/SingleAudiobookInformation";
import SingleAudiobookBorrowHistory from "../components/SingleAudiobookBorrowHistory";
import SingleAudiobookWaitingTime from "../components/SingleAudiobookWaitingTime";
import SingleAudiobookBorrowTime from "../components/SingleAudiobookBorrowTime";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import getAudiobook from "../api/getAudiobook";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";

const AudiobookView = ({ history }) => {

  const { id } = useParams()
  const loggedUser = useSelector((state) => state.authentication.user);
  const [audiobook, setAudiobook] = useState("")


  useEffect(() => {

    const getAudiobookFromDb = async (id) => {

      await getAudiobook(id)
        .then(res => {
          if (res) {
            setAudiobook(res)
          }
        })
    }

    getAudiobookFromDb(id)
  }, [])
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <PlainContainer
        heightInVh={4.5}
        widthInVw={0}
        customStyles={{
          top: "10vh",
          position: "absolute",
          left: "2.5vw",
          paddingBottom: "0px",
          backgroundColor: "transparent",
        }}
      >

        <Button
          text={
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
              />
              Powrót
            </div>
          }
          onClick={() => history.goBack()}
          styleOptions={{
            height: "100%",
          }}
        />
      </PlainContainer>

      <PlainContainer
        customStyles={{
          top: "15.5vh",
          width: "95vw",
          height: "20vh",
          position: "absolute",
          left: "2.5vw",
          paddingBottom: "0px",
        }}
      >
        <SingleAudiobookInformation audiobook={audiobook} />
      </PlainContainer>

      <PlainContainer
        heightInVh={'auto'}
        widthInVw={95}
        customStyles={{
          top: "37vh",
          position: "absolute",
          left: "2.5vw",
          paddingBottom: "0px",
        }}
      >
        <SingleAudiobookBorrowHistory />
      </PlainContainer>
      <Footer />
    </>
  );
};

export default AudiobookView;

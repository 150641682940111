import PlainContainer from "./PlainContainer";
import SummaryEntry from "./SummaryEntry";
import SummaryEntryLabel from "./SummaryEntryLabel";
import VerticalLine from "./VerticalLine";

const SummaryTable = () => {
  return (
    <PlainContainer
      widthInVw={95}
      heightInVh={8}
      customStyles={{
        top: "15vh",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingBottom: "0px",
      }}
    >
      <>
        <SummaryEntryLabel text={"Oczekujący na aktywacje konta"} />
        <SummaryEntry value={"10000"} />
        <VerticalLine
          customStyles={{
            height: "6vh",
          }}
        />
        <SummaryEntryLabel text={"Audiobooki zakupione"} />
        <SummaryEntry value={"1500"} />
        <VerticalLine
          customStyles={{
            height: "6vh",
          }}
        />
        <SummaryEntryLabel text={"Audiobooki wypożyczone"} />
        <SummaryEntry value={"1000"} />
        <VerticalLine
          customStyles={{
            height: "6vh",
          }}
        />
        <SummaryEntryLabel text={"Audiobooki na stanie"} />
        <SummaryEntry value={"2350"} />
      </>
    </PlainContainer>
  );
};

export default SummaryTable;

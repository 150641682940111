import PlainContainer from "../PlainContainer";
import FragmentDescription from "../FragmentDescription";
import ActiveFragmentTable from "./ActiveFragmentTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const ActiveUsersFragment = ({ history }) => {
  return (
    <PlainContainer
      widthInVw={95}
      heightInVh={24}
      customStyles={{
        top: "49vh",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingBottom: "0px",
      }}
    >
      <FragmentDescription
        description="Lista aktywnych kont"
        styleOptions={{
          fontSize: "calc(0.75vw + 5px)",
          textAlign: "center",
          color: "black",
        }}
      />
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        style={{ position: "absolute", right: "3px", top: "3px" }}
        onClick={() => {
          history.push(`${process.env.PUBLIC_URL}/users`);
        }}
        className="open-new-view-button"
      />
      <ActiveFragmentTable
        names={["Numer karty bibliotecznej", "Nazwisko", "Imię", "Stan"]}
        sizes={["20vw", "20vw", "20vw", "20vw"]}
      />
    </PlainContainer>
  );
};

export default withRouter(ActiveUsersFragment);

import { useEffect, useState } from "react";
import { getDateTime } from "../utils/formatter";
import Button from "./Button";
import Spinner from "./Spinner/Spinner";
import { USER_STATUS } from "../utils/constants";

const SingleUserStatus = ({ status, date, openActivateDeactivateModal, openUserDeleteModal }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (date) {
      setLoading(false);
    }
  }, [status, date]);

  return (
    <>
      {!loading ? (
        status === USER_STATUS.Active ? (
          <>
            <div
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <span style={{ color: "green", fontSize: "calc(20px + 0.5vw)" }}>
                Aktywny
              </span>
              <span style={{ display: "block", fontSize: "calc(5px + 0.4vw)" }}>
                Od {getDateTime(date)}
              </span>
            </div>
            <div className="row">
              <div className="col" >
                <Button
                  text="Dezaktywuj"
                  styleOptions={{
                    textAlign: "center",
                    margin: "5px auto",
                    padding: "0.5rem 1rem",
                  }}
                  onClick={openActivateDeactivateModal}
                />
              </div>
              <div className="col" >
                <Button
                  text="Usuń"
                  styleOptions={{
                    textAlign: "center",
                    margin: "5px auto",
                    padding: "0.5rem 1rem",
                  }}
                  onClick={openUserDeleteModal}
                />
              </div>
            </div>
          </>
        ) : status === USER_STATUS.Inactive ? (
          <>
            <div
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <span style={{ color: "red", fontSize: "calc(20px + 0.5vw)" }}>
                Nieaktywny
              </span>
              <span style={{ display: "block", fontSize: "calc(5px + 0.4vw)" }}>
                Od {getDateTime(date)}
              </span>
            </div>
            <div className="row">
              <div className="col" >
                <Button
                  text="Aktywuj"
                  styleOptions={{
                    textAlign: "center",

                    margin: "5px auto",
                    padding: "0.5rem 0.5rem",
                  }}
                  onClick={openActivateDeactivateModal}
                />
              </div>
              <div className="col" >
                <Button
                  text="Usuń"
                  styleOptions={{
                    textAlign: "center",
                    margin: "5px auto",
                    padding: "0.5rem 1rem",
                  }}
                  onClick={openUserDeleteModal}
                />
              </div>
            </div>
          </>
        ) : (<>
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <span style={{ color: "blue", fontSize: "calc(20px + 0.5vw)" }}>
              Usunięty
            </span>

          </div>

        </>)
      ) : (
        <div style={{ textAlign: "-webkit-center" }}>
          <Spinner customStyles={{ fontSize: "10px" }} />
        </div>
      )}
    </>
  );
};

export default SingleUserStatus;

const listOfBooks = (() => {
  

})()

const initialStore = {
    listOfBooks
}

const audiobookiBooks = (store = initialStore, action) => {
  switch (action.type) {
    case 'UPDATE_LOCAL_AUDIOBOOK_BOOKS':
      return { ...store, listOfBooks: action.payload }
    default:
      return store
  }
}

export default audiobookiBooks
import ActiveFragmentTableEntry from "./ActiveFragmentTableEntry";
import { useEffect } from "react";

let users = require("../../mock/mock_active_users.json");

const ActiveFragmentTableContents = ({ sizes }) => {
  useEffect(() => {
    console.log(users);
  }, []);

  return (
    <div
      style={{
        height: "18vh",
        width: "100%",
        float: "right",
        // backgroundColor: "black",
        overflowY: "overlay",
      }}
    >
      {users.map((user) => {
        return (
          <ActiveFragmentTableEntry user={user} sizes={sizes} key={user.id} />
        );
      })}
    </div>
  );
};

export default ActiveFragmentTableContents;

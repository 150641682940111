import AudiobooksListFragmentTableEntry from "./AudiobooksListFragmentTableEntry";
import { useEffect } from "react";

let audiobooks = require("../../mock/mock_audiobooks.json");

const AudiobooksListFragmentTableContents = ({ sizes }) => {
  useEffect(() => {
    console.log(audiobooks);
  }, []);
  return (
    <div
      style={{
        height: "18vh",
        width: "100%",
        float: "right",
        overflowY: "overlay",
      }}
    >
      {audiobooks.map((audiobook) => {
        return (
          <AudiobooksListFragmentTableEntry
            audiobook={audiobook}
            sizes={sizes}
            key={audiobook.id}
          />
        );
      })}
    </div>
  );
};

export default AudiobooksListFragmentTableContents;

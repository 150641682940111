import ActiveFragmentTableContents from "./ActiveFragmentTableContents";
import ActiveFragmentTableHeaders from "./ActiveFragmentTableHeaders";

const ActiveFragmentTable = ({ names, sizes }) => {
  return (
    <>
      <ActiveFragmentTableHeaders names={names} sizes={sizes} />
      <ActiveFragmentTableContents sizes={sizes} />
    </>
  );
};

export default ActiveFragmentTable;

const initialStore = {
  message: '',
}

const dummy = (store = initialStore, action) => {
  switch (action.type) {
    default:
      return store
  }
}

export default dummy;
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "../Button";
import "./LoginCredentials.css";

import { onLoginUserInLibrarian } from "../../redux/actions/onLoginUser";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const loginSchema = yup.object().shape({
  username: yup.string().required("Pole nie może być puste!"),
  password: yup.string().required("Pole nie może być puste!"),
});

const LoginCredentials = ({ history }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [fetchErrors, setFetchErrors] = useState("");

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (e) => {
    // e.preventDefault();
    await onLoginUserInLibrarian({ username, password, history })
      .then((response) => {
        setFetchErrors("Nie znaleziono użytkownika. Spróbuj ponownie.");
      }
      );
  };

  return (
    <div className="login-view-credentials">
      <form onSubmit={handleSubmit(onSubmit)} className="login-view-form">
        <label htmlFor="user-login" className="login-form-label">
          Login
          {errors.username && (
            <span
              style={{
                color: "red",
                fontSize: "13px",
                fontWeight: "bold",
                float: "right",
                lineHeight: "28px",
              }}
            >
              {errors.username.message}
            </span>
          )}
        </label>
        <input
          id="user-login"
          className="login-form-input"
          type="login"
          onChange={(e) => setUserName(e.target.value)}
          ref={register}
          name="username"
        ></input>
        <label htmlFor="user-password" className="login-form-label">
          Hasło
          {errors.password && (
            <span
              style={{
                color: "red",
                fontSize: "13px",
                fontWeight: "bold",

                lineHeight: "28px",
              }}
            >
              {errors.password.message}
            </span>
          )}
        </label>
        <input
          id="user-password"
          className="login-form-input"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          ref={register}
          name="password"
        ></input>
        {/* <button type="submit">submit</button> */}
        <Button
          text={"Zaloguj"}
          type="submit"
          styleOptions={{
            margin: "2em auto",
            width: "150px",
          }}
        />
        <p
          style={{
            color: "red",
            position: "absolute",
            width: "20vw",
            textAlign: "center",
            fontSize: "13px",
            fontWeight: "bold",
            left: "2.5vw",
          }}
        >
          {fetchErrors}
        </p>
      </form>
    </div >
  );
};

export default withRouter(LoginCredentials);

import "./LoginQRCode.css";
import logoIcon from "../../icons/logo.svg";
// import { QRCode } from "react-qr-svg";
const LoginQRCode = () => {
  return (
    <div className="login-view-qr">
      <div
        style={{
          width: "20vw",
          height: "20vw",
          backgroundColor: "#99A8F2",
          lineHeight: "20vw",
          color: "white",
          margin: "0 2.5em"
        }}
      >
        {/* <QRCode
                bgColor="white"
                fgColor="#9d4e91"
                level="Q"
                style={{ width: "45%" }}
                value={this.props.loginQRCodeString}
              /> */}
        {/* Kod QR */}
        {/* <img
          src={logoIcon}
          style={{
            height: "calc(6vw + 14px)",
          }}
          alt=""
        /> */}
      </div>
    </div >
  );
};

export default LoginQRCode;

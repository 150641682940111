import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TopBarNavigationElement from "./TopBarNavigationElement";

const TopBarNavigation = ({ location }) => {
  const path = location.replace(`${process.env.PUBLIC_URL}/`, "");
  const user = useSelector(state => state.authentication.user)

  return (
    <div
      style={{
        position: "absolute",
        left: "9vw",
        top: 24,
        display: "flex",

      }}
    >
      <Link to={`${process.env.PUBLIC_URL}/users`} style={{ textDecoration: "none", }}>
        <TopBarNavigationElement
          isFirst
          status={path.startsWith("users") ? "active" : null}
        >
          Czytelnicy
        </TopBarNavigationElement>
      </Link>
      <Link to={`${process.env.PUBLIC_URL}/audiobooks`} style={{ textDecoration: "none", }}>
        <TopBarNavigationElement
          status={path.startsWith("audiobooks") ? "active" : null}
        >
          Audiobooki
        </TopBarNavigationElement>
      </Link>

      {
        user.business_type === "audiobook_admin" ?
          <Link to={`${process.env.PUBLIC_URL}/libraries`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              status={path.startsWith("libraries") ? "active" : null}
            >
              Biblioteki
            </TopBarNavigationElement>
          </Link>
          : null}

      {
        user.business_type === "audiobook_admin" || user.business_type === "library" ?
          <Link to={`${process.env.PUBLIC_URL}/employees-library`} style={{ textDecoration: "none", }}>
            <TopBarNavigationElement
              isLast
              status={path.startsWith("employees") ? "active" : null}
            >
              Pracownicy
            </TopBarNavigationElement>
          </Link>
          : null}

    </div>
  );
};

export default TopBarNavigation;
